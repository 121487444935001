import { ReactElement } from "react";

interface LinkPropType {
  url: string;
  newTab?: boolean;
  children: string | ReactElement;
}

const Link = (props: LinkPropType) => {
  const {newTab = false} = props
  return (
    <a
      href={props.url}
      target={newTab ? "_blank" : "_self"}
      rel="noreferrer noopener"
      className="cursor-pointer"
    >
      {props.children}
    </a>
  );
};

export default Link;
