import { FaGithub, FaLinkedinIn } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import coderImg from "./assets/images/coder.avif";
import Link from "./components/core/Link";

const App = () => {
  return (
    <>
      <div className="flex justify-center w-full px-4 py-8">
        {/* Profile Image */} 
        <div className=" flex items-center justify-center h-[calc(100vh-4rem)] w-[30vw] sticky top-8">
          <img
            className="m-8 rounded-2xl shadow-lg w-full"
            src={coderImg}
            alt="coder"
          />
        </div>
        <div className="flex w-[64rem] px-4">
          {/* Resume */}
          <div className="border shadow-lg shadow-slate-300 rounded w-full p-8 font-medium">
            {/* Personal Info Section */}
            <section
              id="personal-info"
              className="flex items-center flex-col py-4"
            >
              <h1>OM PRAKASH THAKUR</h1>
              <h4>Full Stack Developer</h4>
              <h4>UAE Resident, Indian | Ph. No. +971 50 532 9751</h4>
              <h4></h4>
            </section>
            <section
              id="social-contact"
              className="flex flex-row justify-evenly py-4"
            >
              <Link url="https://github.com/om7271prakash" newTab>
                <div
                  id="github"
                  className="flex flex-row justify-center items-center"
                >
                  <div className="px-1">
                    <FaGithub />
                  </div>
                  <div className="px-1">
                      @om7271prakash
                  </div>
                </div>
              </Link>
              <Link url="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=om7271prakash@gmail.com" newTab>
                <div
                  id="gmail"
                  className="flex flex-row justify-center items-center"
                >
                  <div className="px-1">
                    <IoMdMail />
                  </div>
                  <div className="px-1">om7271prakash@gmail.com</div>
                </div>
              </Link>
              <Link url="https://www.linkedin.com/in/om7271prakash/" newTab>
                <div
                  id="linkedin"
                  className="flex flex-row justify-center items-center"
                >
                  <div className="px-1">
                    <FaLinkedinIn />
                  </div>
                  <div className="px-1">@om7271prakash</div>
                </div>
              </Link>
            </section>
            <section id="career-objective" className="flex flex-col py-4">
              <h2 className="underline decoration-2">CAREER OBJECTIVE</h2>
              <p>
                There can't be any fixed goal for me. Because I love to learn about new technology and implement them in efficient way.
              </p>
            </section>
            <section id="experience" className="flex flex-col py-4">
              <h2 className="underline decoration-2">EXPERIENCE</h2>
              <section>
                <p className="flex justify-between">
                  <div className="flex flex-row">
                    <h4>Frontend Developer - <Link url="https://www.mashreq.com/" newTab>Mashreq Bank</Link></h4>
                    <div className="ps-2">
                      (on-site through <Link url="http://optimumsolutions.biz/" newTab>Optimum Solutions</Link>) <Link url="https://maps.app.goo.gl/2NnKScemik8nkW9H6" newTab><i>Dubai, UAE</i></Link>
                    </div>
                  </div>
                  <div>Jan. 2023 - Present</div>
                </p>
                <p className="flex justify-between">
                  <ul className="list-disc px-8">
                    <li>
                      Develop/manage/fix the <b>React</b> components in a scalable way.
                    </li>
                    <li>
                      Test cases using <b>Jest</b>.
                    </li>
                    <li>
                      Maintain private Web Package (<b>Typescript</b>).
                    </li>
                    <li>
                      React Native App (Android and iOS), state management using <b>Redux(Thunk)</b>.
                    </li>
                    <li>Focus on mobile first Web App.</li>
                  </ul>
                </p>
              </section>
              <section>
                <p className="flex justify-between">
                  <div className="flex flex-row">
                    <h4>Full Stack - <Link url="https://www.supervisionit.com/" newTab>Supervision IT Solutions LLP</Link></h4>
                    <div className="ps-2">
                      <i><Link url="https://maps.app.goo.gl/DekDBM2jqzb1STpV9" newTab>Mohali, Punjab</Link></i>
                    </div>
                  </div>
                  <div>March 2019 - Dec. 2022</div>
                </p>
                <p className="flex justify-between">
                  <ul className="list-disc px-8">
                    <li>
                      Turned <b>Figma</b> mockups to performant functional components using <b>React</b> (with <b>Typescript</b>) and{" "}
                      <b>Tailwind CSS</b>.
                    </li>
                    <li>
                      Design <b>GraphQl API</b> and fetching data from Headless CMS (<b>Contentfull</b> and <b>Shopify</b>).
                    </li>
                    <li>
                      Find bugs and fix them, optimization and code sanity.
                    </li>
                  </ul>
                </p>
              </section>
              <section>
                <p className="flex justify-between">
                  <div className="flex flex-row">
                    <h4>Full Stack - <Link url="https://www.brihaspatitech.com/" newTab>The Brihaspati Infotech PVT. LTD.</Link></h4>
                    <div className="ps-2">
                      <i><Link url="https://maps.app.goo.gl/gnqtHtxsK26RkUXTA" newTab>Mohali, Punjab</Link></i>
                    </div>
                  </div>
                  <div>April 2018 - Oct. 2018</div>
                </p>
                <p className="flex justify-between">
                  <ul className="list-disc px-8">
                    <li>
                      Owned complete features from gathering information to
                      handling deployment to the production.
                    </li>
                    <li>
                      Develop NodeJS API using framework i.e. <b>Express JS</b> and design frontend using <b>React JS</b>.
                    </li>
                    <li>
                      Provide best sevice solutions using <b>PHP frameworks</b> (YII2, Laravel, CI, etc.) and <b>CMS</b> (Wordpress).
                    </li>
                  </ul>
                </p>
              </section>
              <section>
                <p className="flex justify-between">
                  <div className="flex flex-row">
                    <h4>Full Stack - <Link url="https://baselineitdevelopment.com/" newTab>Baseline IT Development PVT. LTD.</Link></h4>
                    <div className="px-2">
                      <i><Link url="https://maps.app.goo.gl/coNQWXH4LYcrPkNP8" newTab>Mohali, Punjab</Link></i>
                    </div>
                  </div>
                  <div>Jan. 2017 - March 2018</div>
                </p>
                <p className="flex justify-between">
                  <ul className="list-disc px-8">
                    <li>
                      Main focus is <b>e-Commerce Solutions</b> (WooCommerce, Shopify).
                    </li>
                    <li>
                      Worked with many <b>payment methods</b> (PayPal, Stripe).
                    </li>
                    <li>
                      Develop many API endpoints connected with <b>MySQL Database</b>.
                    </li>
                    <li>
                      Experienced with <b>AWS Associate, AWS S3, EC2</b>.
                    </li>
                  </ul>
                </p>
              </section>
            </section>

            <section id="experience" className="flex flex-col py-4">
              <h2 className="underline decoration-2">PROJECTS (Latest) </h2>
              <section>
                <p className="flex justify-between">
                  <Link url="https://jaxxon.com" newTab>
                    <div className="flex flex-row">
                      <h4>Jaxxon -</h4>
                      <div className="ps-2">
                        <i>https://jaxxon.com</i>
                      </div>
                    </div>
                  </Link>
                </p>
                <div className="flex flex-col ps-8">
                  <div className="flex flex-row">
                    <h4>Objective:</h4>
                    <div className="px-2">
                      E-commerce and selling ornaments (i.e. Rings, Chains and Bracelets of Gold and Silvers).
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <h4>Technology Used:</h4>
                    <div className="px-2">
                      React JS, Gatsby JS, GraphQL, Shopify API, Contentful.
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <h4>Responsibilities:</h4>
                    <ul className="list-disc px-8">
                      <li>
                        Working on many components like cart, product, etc.
                      </li>
                      <li>
                        Implementing complex operations (Context API, Shopify Checkout, etc).
                      </li>
                      <li>Bug fixing is the main challenge in this project.</li>
                      <li>Handling Contentful models and data.</li>
                      <li>
                        Developing GraphQL Queries and implementing them according to the requirement.
                      </li>
                      <li>
                        Managing code and keeping track of code using GitLab.
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section>
                <p className="flex justify-between">
                  <Link url="https://blog.logrocket.com" newTab>
                    <div className="flex flex-row">
                      <h4>Log Rocket -</h4>
                      <div className="px-2">
                        <i>https://blog.logrocket.com</i>
                      </div>
                    </div>
                  </Link>
                </p>
                <div className="flex flex-col ps-8">
                  <div className="flex flex-row">
                    <h4>Objective:</h4>
                    <div className="px-2">
                      Technical Blogs related to the Computer Science field
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <h4>Technology Used:</h4>
                    <div className="px-2">
                      React JS, Gatsby JS, TypeScript GraphQL, WordPress.
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <h4>Responsibilities:</h4>
                    <ul className="list-disc px-8">
                      <li>
                        Worked on blog design and interpreted WordPress Posts into React components.
                      </li>
                      <li>
                        Fixed bugs related to website build and plugins setup.
                      </li>
                      <li>Managed code and kept track of code using GitHub.</li>
                    </ul>
                  </div>
                </div>
              </section>
              <section>
                <p className="flex justify-between">
                  <Link url="https://vise.com/" newTab>
                    <div className="flex flex-row">
                      <h4>Vise -</h4>
                      <div className="px-2">
                        <i>https://vise.com</i>
                      </div>
                    </div>
                  </Link>
                </p>
                <div className="flex flex-col ps-8">
                  <div className="flex flex-row">
                    <h4>Objective:</h4>
                    <div className="px-2">Investment consultancy.</div>
                  </div>
                  <div className="flex flex-row">
                    <h4>Technology Used:</h4>
                    <div className="px-2">
                      React JS, Next JS, Stackbit CMS, Tailwind.
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <h4>Responsibilities:</h4>
                    <ul className="list-disc px-8">
                      <li>
                        Setup of project on Stackbit and designed directory structure of project.
                      </li>
                      <li>Designed all projects using Next JS and JSX.</li>
                      <li>
                        Build reusable section/block using CMS functionality.
                      </li>
                      <li>
                        BIntegrated website with GA4 and GTM to track every visit, click and form submission.
                      </li>
                      <li>Managed website build and deployment.</li>
                    </ul>
                  </div>
                </div>
              </section>
            </section>
            <section id="experience" className="flex flex-col py-4">
              <h2 className="underline decoration-2">EDUCATION</h2>
              <p className="flex flex-col ps-4">
                <div className="flex justify-between">
                  <div className="flex flex-row">
                    <h4>
                      <Link url="https://www.himtu.ac.in/" newTab>Himachal Pradesh Technical University - </Link> 
                      <Link url="https://maps.app.goo.gl/fuggrXm6fGhZisZ49" newTab>Hamirpur</Link>
                    </h4>
                  </div>
                  <div>2012-2016</div>
                </div>
                <div>
                  <i>Bachelor of Technology (Computer Science Engineering)</i>
                </div>
              </p>
            </section>
            <section id="experience" className="flex flex-col py-4">
              <h2 className="underline decoration-2">SKILLS</h2>
              <p className="flex flex-col ps-4 py-2">
                <div className="flex flex-row">
                  <h4>Language:</h4>
                  <div className="px-2">
                    C, JavaScript, Node JS, PHP, SQL, HTML, CSS.
                  </div>
                </div>
                <div className="flex flex-row">
                  <h4>Frameworks/Libraries:</h4>
                  <div className="px-2">
                    ReactJS, Next JS, Gatsby JS, React Native, Redux(Thunk, Saga), Tailwind CSS, Typescript, Express JS.
                  </div>
                </div>
                <div className="flex flex-row">
                  <h4>Backend:</h4>
                  <div className="px-2">
                    API Development (REST and GraphQL), AWS (S3, EC2), Database (MySQL, NoSQL).
                  </div>
                </div>
                <div className="flex flex-row">
                  <h4>Tools:</h4>
                  <div className="px-2">
                    Git, Linux, GitLab, GA4, GTM, Clickup, Slack, Azure (Agile)
                  </div>
                </div>
              </p>
            </section>
            <section id="experience" className="flex flex-col py-4">
              <h2 className="underline decoration-2">DECLARATION</h2>
              <p className="flex justify-between ps-4 py-2">
                I hereby declare that the information furnished above is true to the best of my knowledge and I am also confident of my ability to work in a team.
              </p>
            </section>
          </div>
        </div>
      </div>
      <div className="h-32 w-full bg-slate-400"></div>
    </>
  );
};

export default App;
